<template>
  <HandleDataForms v-bind:apiSource="mongo" v-bind:dataSource="'/'+database" title="Formulario Nem" collection="nem" templateTable="TableNem" templateTableModal="TableNemModal" v-bind:dataExcel="dataExcel" />
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import HandleDataForms from '@/components/HandleDataForms.vue'

export default {
  components: {
    HandleDataForms,
  },
  setup() {
    const store = useStore()
    const mongo = computed(() => store.getters.getMongoURL)
    const database = computed(() => store.getters.getDBDashboard)
    const startDate = computed(() => moment().startOf('week').format('YYYY-MM-DD'))
    const endDate = computed(() => moment().format('YYYY-MM-DD'))

    let dataExcel = {
      fields: {
        createdAt: 'Fecha',
        nombre: 'Nombre',
        apellido_paterno: 'Apellido paterno',
        apellido_materno: 'Apellido materno',
        nem: 'Nem',
        nacionalidad_nombre: 'Nacionalidad',
        tipo_documento: 'Tipo de  documento',
        numero_documento: 'Nº de documento',
        email: 'E-mail',
        codigo_celular: 'Código celular',
        celular: 'Número celular',
        codigo_area: 'Código teléfono',
        telefono: 'Número teléfono',

        region_nombre: 'Región', // Region es un valor compuesto
        comuna_nombre: 'Comuna', // Comuna es un valor compuesto
        sede_nombre: 'Colegio', // Colegio es un valor compuesto

        ano_cursado: 'Año cursado',
        ano_egreso: 'Año de egreso',

        postulacion: [
          {
            carrera: {
              nombre: 'Carrera 1 - Nombre',
            },
            institucion: 'Carrera 1 - Institución',
            jornada: 'Carrera 1 - Jornada',
            zona_admision: {
              nombre: 'Carrera 1 - Zona de admisión',
            },
          },
          {
            carrera: {
              nombre: 'Carrera 2 - Nombre',
            },
            institucion: 'Carrera 2 - Institución',
            jornada: 'Carrera 2 - Jornada',
            zona_admision: {
              nombre: 'Carrera 2 - Zona de admisión',
            },
          },
          {
            carrera: {
              nombre: 'Carrera 3 - Nombre',
            },
            institucion: 'Carrera 3 - Institución',
            jornada: 'Carrera 3 - Jornada',
            zona_admision: {
              nombre: 'Carrera 3 - Zona de admisión',
            },
          },
        ],
        // carrera_1: 'Codigo Carrera 1',
        // carrera_2: 'Codigo Carrera 2',
        // carrera_3: 'Codigo Carrera 3',
        utm_source: 'Origen UTM',
        utm_campaign: 'Campaña UTM',
        utm_medium: 'Medio UTM',
        device: 'Dispositivo', 
        historial: 'Historial',
      },
      query: {
        collection: 'nem',
        date_field: 'createdAt',
        fecha_inicio: startDate.value + ' 00:00:00',
        fecha_fin: endDate.value + ' 23:59:59',
      },
    }

    return {
      mongo,
      dataExcel,
      database
    }
  },
}
</script>
